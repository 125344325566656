<template>
  <div>
    <MainCard
      v-if="!isLoadingTable"
      id="packet-card"
    >
      <template v-slot:body>
        <DataTablePagination
          :headers="headers"
          :search.sync="search"
          :items="packets"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          title="Paket"
          subtitle="Halaman untuk menambahkan paket buku"
          add-btn="Tambah data"
          :is-loading="isLoadingData"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <v-dialog
      v-model="addFormDialog"
      persistent
      width="1080"
    >
      <v-card>
        <v-card-title class="headline justify-center">
          Tambah Paket Buku
          <v-icon
            style="position:absolute;top: 20px;right: 20px"
            @click="addFormDialog = false"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-tabs
            v-model="tab"
            show-arrows
            fixed-tabs
            class="justify-space-between"
          >
            <v-tab
              v-for="tabItem in tabs"
              :key="tabItem.icon"
              class="d-flex "
              :disabled="secondTabDisable(tabItem.title)"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tabItem.icon }}
              </v-icon>
              <span>{{ tabItem.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab"
            class="px-5"
          >
            <v-tab-item>
              <packet-form
                :reset-form.sync="resetForm"
                @next-tab="nextTabHandler"
                @close-dialog="handleCloseDialog"
              ></packet-form>
            </v-tab-item>
            <v-tab-item>
              <packet-option
                :packet-data.sync="packet"
                @prev-tab="prevTabHandler"
                @close-dialog="handleCloseDialog"
                @success-save="successSave"
              ></packet-option>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- edit -->
    <v-dialog
      v-model="editFormDialog"
      persistent
      width="1080"
    >
      <v-card>
        <v-card-title class="headline justify-center">
          Edit Paket Buku
          <v-icon
            style="position:absolute;top: 20px;right: 20px"
            @click="editFormDialog = false"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-tabs
            v-model="tab"
            show-arrows
            fixed-tabs
            class="justify-space-between"
          >
            <v-tab
              v-for="tabItem in tabs"
              :key="tabItem.icon"
              class="d-flex "
              :disabled="secondTabDisable(tabItem.title)"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tabItem.icon }}
              </v-icon>
              <span>{{ tabItem.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab"
            class="px-5"
          >
            <v-tab-item>
              <packet-form
                :edit-uuid.sync="editUuid"
                @next-tab="nextTabHandler"
                @close-dialog="handleCloseDialog"
              ></packet-form>
            </v-tab-item>
            <v-tab-item>
              <packet-option
                :packet-data.sync="packet"
                @prev-tab="prevTabHandler"
                @close-dialog="handleCloseDialog"
              ></packet-option>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      :visible="confirmDialog"
      persistent
      :width="600"
      :is-loading-button="isLoadingButton"
      header="Hapus Paket"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import PacketForm from './PacketForm.vue'
import PacketOption from './PacketOption.vue'

export default {
  components: {
    // ModalDialog,
    ConfirmDialog,
    DataTablePagination,
    SkeletonLoaderTable,
    MainCard,
    PacketForm,
    PacketOption,
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
      search: '',
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      addFormDialog: false,
      modalDialog: false,
      editFormDialog: false,
      confirmDialog: false,
      resetForm: false,
      editUuid: null,
      page: 1,
      totalItems: 10,
      totalPages: 0,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama Paket', value: 'name' },
        { text: 'Harga', value: 'price' },
        { text: 'Total Buku', value: 'total_library' },
        { text: 'Deskripsi', value: 'description' },
        { text: 'Total E-book', value: 'total_ebook' },
        { text: 'Total Video Book', value: 'total_video_book' },
        { text: 'Total Audio Book', value: 'total_audio_book' },
        { text: 'Actions', value: 'actions' },
      ],
      packets: [],
      packet: {
        uuid: '',
        name: '',
        price: '',
        total_book: '',
        description: '',
      },
      tab: '',
      tabs: [{ title: 'Informasi Paket' }, { title: 'Pilih Paket' }],
      filterQuery: {
        package: '',
      },
      selectedPacket: {},
    }
  },
  computed: {},
  watch: {
    page: {
      handler() {
        this.listPacket(this.filterQuery)
      },
    },
  },
  async mounted() {
    this.isLoadingTable = true
    await this.listPacket()
    this.isLoadingTable = false
  },
  methods: {
    secondTabDisable(tabTitle) {
      if (tabTitle === 'Pilih Paket' && !this.packet.name && !this.packet.price) return true

      return false
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    showFormAdd() {
      this.addFormDialog = true
    },
    async listPacket(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('packet', { ...params, page: this.page }).then(
        ({ data }) => {
          this.packets = data.data.map((packets, index) => ({
            ...packets,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },
    showFormEdit(uuid) {
      this.editUuid = uuid
      this.packet.uuid = uuid
      this.editFormDialog = true
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.packet.uuid = uuid
    },

    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('packet', this.packet.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listPacket(this.filterQuery)
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    nextTabHandler(data) {
      this.packet = data
      this.tab += 1
    },
    prevTabHandler() {
      this.tab -= 1
    },
    handleCloseDialog() {
      this.editUuid = null
      this.editFormDialog = false
      this.addFormDialog = false
      this.resetForm = true
      this.packet = {
        uuid: '',
        name: '',
        price: '',
        total_book: '',
        description: '',
      }
      this.listPacket(this.filterQuery)
      this.tab = ''
    },
    successSave() {
      this.resetForm = true
    },
    searchHandler(data) {
      this.filterQuery.package = data
      this.page = 1
      this.listPacket(this.filterQuery)
    },
  },
}
</script>

<style>
</style>
